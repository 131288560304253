import React from 'react';
import { type StoreApi, createStore, useStore } from 'zustand';

export type SubscriptionProvider = 'stripe' | 'revenueCat';

export type AuthCore = {
  firebaseUid: string | null;
  isPremium: boolean;
  subscriptionProvider: SubscriptionProvider | null;
};

export type AuthState = AuthCore & {
  logout: () => void;
  updateState: (state: AuthCore) => void;
};

export type AuthStore = StoreApi<AuthState>;

export function createAuthStore(state: AuthCore): AuthStore {
  return createStore<AuthState>((set) => ({
    ...state,
    logout: () => set({ firebaseUid: null, isPremium: false }),
    updateState: (state) => set(state),
  }));
}

export const AuthContext = React.createContext<AuthStore | null>(null);

function useAuthContext(): AuthStore {
  const store = React.useContext(AuthContext);
  if (!store) {
    throw new Error('Missing AuthContext in the tree');
  }
  return store;
}

export function useAuthStore<T>(selector: (state: AuthState) => T): T {
  const store = useAuthContext();
  return useStore(store, selector);
}

const firebaseUidSelect = (state: AuthState) => state.firebaseUid;
export function useUserId(): string | null {
  return useAuthStore(firebaseUidSelect);
}

const isConnectedSelect = (state: AuthState) => !!state.firebaseUid;
export function useIsConnected(): boolean {
  return useAuthStore(isConnectedSelect);
}

const isPremiumSelect = (state: AuthState) => state.isPremium;
export function useIsPremium(): boolean {
  return useAuthStore(isPremiumSelect);
}

const subscriptionProviderSelect = (state: AuthState) =>
  state.subscriptionProvider;
export function useSubscriptionProvider(): SubscriptionProvider | null {
  return useAuthStore(subscriptionProviderSelect);
}

const logoutSelect = (state: AuthState) => state.logout;
export function useLogout(): () => void {
  return useAuthStore(logoutSelect);
}

const updateStateSelect = (state: AuthState) => state.updateState;
export function useUpdateAuthState(): (state: AuthCore) => void {
  return useAuthStore(updateStateSelect);
}
